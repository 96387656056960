/* You can add global styles to this file, and also import other style files */
* { padding:0;margin:0;}
html, body { height:100%; }
@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}

html {
    scroll-behavior: smooth;
}
